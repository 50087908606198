import * as React from 'react'
import { SportDataType } from 'shared_components/FormSportData/SportData'
import { Button, Col, Row } from 'react-bootstrap'
import { sections } from 'shared_components/sections'
import endOfMonth from 'date-fns/endOfMonth'
import format from 'date-fns/format'
import CancelActiveSport from '../CancelActiveSport'

interface Props {
    initialValues: SportDataType
    onLeave: (section: string, join: boolean, parent?: string) => void
}

interface IPopupData {
    show: boolean
    dictKey: string
    wasActive: boolean
    parent?: string | undefined
}

const ActiveSectionsData = ({ initialValues, onLeave }: Props): JSX.Element => {
    const [initialDataState, setInitialDataState] = React.useState<string[]>([])
    const [leaveButtonClicked, setLeaveButtonClicked] = React.useState<boolean>(false)

    const [popupData, setPopupData] = React.useState<IPopupData>({
        show: false,
        dictKey: '',
        wasActive: false,
        parent: undefined,
    })

    React.useEffect(() => {
        const dataState: string[] = []

        Object.entries(initialValues.sportData).forEach(([key, section]) => {
            if (section === true) {
                dataState.push(key)
            } else if (typeof section === 'object') {
                return Object.entries(section).map(([key, subSection]) => {
                    if (subSection === true) {
                        dataState.push(key)
                    }
                })
            }
        })

        setInitialDataState(dataState)
    }, [])

    React.useEffect(() => {
        if (leaveButtonClicked) {
            return
        }

        const dataState: string[] = []

        Object.entries(initialValues.sportData).forEach(([key, section]) => {
            if (section === true) {
                dataState.push(key)
            } else if (typeof section === 'object') {
                return Object.entries(section).map(([key, subSection]) => {
                    if (subSection === true) {
                        dataState.push(key)
                    }
                })
            }
        })

        setInitialDataState(dataState)
    }, [initialValues])

    const renderSectionName = (section: string): string => {
        return sections[section] || section
    }

    const renderLeaveButton = (key: string, wasActive: boolean, parent?: string): JSX.Element => {
        if (wasActive) {
            return (
                <Button
                    onClick={() => {
                        setLeaveButtonClicked(true)
                        setPopupData({ show: true, dictKey: key, wasActive: true, parent: parent })
                        setTimeout(() => {
                            setLeaveButtonClicked(false)
                        }, 800)
                    }}
                    size="sm"
                    variant="primary"
                    disabled={leaveButtonClicked}
                >
                    austreten
                </Button>
            )
        }

        return (
            <Button
                onClick={() => {
                    setLeaveButtonClicked(true)
                    setPopupData({ show: true, dictKey: key, wasActive: false, parent: parent })
                    setTimeout(() => {
                        setLeaveButtonClicked(false)
                    }, 800)
                }}
                size="sm"
                variant="outline-primary"
                disabled={leaveButtonClicked}
            >
                austreten
            </Button>
        )
    }

    const getEndingDate = (sectionKey: string, key: string | undefined = undefined) => {
        let endingDate: string | boolean = false
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!key && (initialValues.endingData as any)[sectionKey]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            endingDate = (initialValues.endingData as any)[sectionKey]
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (key && (initialValues.endingData as any)[sectionKey][key]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            endingDate = (initialValues.endingData as any)[sectionKey][key]
        }
        if (endingDate) {
            const date = new Date(endingDate as string)
            const lastDayOfMonth = endOfMonth(date)
            endingDate = format(lastDayOfMonth, 'dd.MM.yyyy')
        }
        return endingDate
    }

    const renderSparten = (): JSX.Element => {
        return (
            <Row>
                {Object.entries(initialValues.sportData).map(([sectionKey, section]) => {
                    if (section === true) {
                        return (
                            <Col xs={12} md={6} lg={4} xl={3} key={sectionKey} className="pb-2">
                                <Row>
                                    <Col xs={6}>
                                        <strong>{renderSectionName(sectionKey)}</strong>
                                    </Col>
                                    <Col xs={6}>
                                        {getEndingDate(sectionKey) && <>Beendet zum {getEndingDate(sectionKey)}</>}
                                        {!getEndingDate(sectionKey) && renderLeaveButton(sectionKey, false)}
                                    </Col>
                                </Row>
                            </Col>
                        )
                    } else if (typeof section === 'object') {
                        return Object.entries(section).map(([key, subSection]) => {
                            if (subSection === true) {
                                return (
                                    <Col xs={12} md={6} lg={4} xl={3} key={key} className="pb-2">
                                        <Row>
                                            <Col xs={6}>
                                                <strong>{renderSectionName(key)}</strong>{' '}
                                            </Col>
                                            <Col xs={6}>
                                                {getEndingDate(key) && <>Beendet zum {getEndingDate(key)}</>}
                                                {!getEndingDate(key) && renderLeaveButton(key, false, sectionKey)}
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            } else if (subSection === false && initialDataState.indexOf(key) !== -1) {
                                return (
                                    <Col xs={4} key={key} className="pb-2">
                                        <Row>
                                            <Col xs={6}>
                                                <strong>{renderSectionName(key)}</strong>{' '}
                                            </Col>
                                            <Col xs={6}>
                                                {getEndingDate(key) && <>Beendet zum {getEndingDate(key)}</>}
                                                {!getEndingDate(key) && renderLeaveButton(key, false, sectionKey)}
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            }
                        })
                    } else if (section === false && initialDataState.indexOf(sectionKey) !== -1) {
                        return (
                            <Col xs={4} key={sectionKey} className="pb-2">
                                <Row>
                                    <Col xs={6}>
                                        <strong>{renderSectionName(sectionKey)}</strong>
                                    </Col>
                                    <Col xs={6}>{renderLeaveButton(sectionKey, true)}</Col>
                                </Row>
                            </Col>
                        )
                    }
                })}
            </Row>
        )
    }

    const closePopup = (show: boolean) => setPopupData({ ...popupData, show: show })

    const cancelActiveSport = (dictKey: string, wasActive: boolean, parent?: string): void => {
        closePopup(false)
        return onLeave(dictKey, wasActive, parent)
    }

    return (
        <div>
            <Row className="pb-4">
                <Col>{renderSparten()}</Col>
            </Row>
            <CancelActiveSport
                {...popupData}
                sectionName={renderSectionName(popupData.dictKey)}
                setShowPopup={closePopup}
                cancelActiveSport={cancelActiveSport}
            />
        </div>
    )
}

export default ActiveSectionsData
