import { Nav, Navbar } from 'react-bootstrap'
import logo from './niendorfer-tsv.jpg'
import styles from './PageHeader.module.scss'

const PageHeader = (): JSX.Element => {
    return (
        <header className={[styles.pageHeader, 'mb-4'].join(' ')}>
            <img src={logo} alt="logo" />
            <Navbar bg="primary" variant="dark">
                <Nav className="justify-content-center">
                    <Nav.Link href="https://www.niendorfer-tsv.de/" className="mr-3">
                        Startseite
                    </Nav.Link>
                    <Nav.Link href="https://www.niendorfer-tsv.de/kontakt.html">Kontakt</Nav.Link>
                </Nav>
            </Navbar>
        </header>
    )
}

export default PageHeader
