import * as React from 'react'
import { useIsMounted } from 'common'
import { Formik } from 'formik'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import FormPersonalData, { PersonalDataType } from 'shared_components/FormPersonalData'
import { ValidationSchemaPersonalDataUpdate } from 'shared_components/FormPersonalData/ValidationSchemaPersonalData'
import { useTypedStoreActions } from 'store/hooks'
import { renderDateFromString } from 'utils/date'
import * as Yup from 'yup'
import { FormMode } from '../../MemberDataTypes'
import PersonalDataCard from './PersonalDataCard'

import styles from 'shared_components/forms.module.scss'

interface Props {
    initialValues: PersonalDataType
    mode: FormMode
    changeFormMode: () => void
}

const PersonalData = ({ initialValues, mode, changeFormMode }: Props): JSX.Element => {
    const isMounted = useIsMounted()
    const validationSchema = Yup.object().shape(ValidationSchemaPersonalDataUpdate)
    const updatePersonalData = useTypedStoreActions((state) => state.personalData.updatePersonalData)
    const updateMemberLoginData = useTypedStoreActions((state) => state.authentication.updateMemberLoginData)

    const onCreate = async (values: PersonalDataType) => {
        try {
            const result = await updatePersonalData(values)
            await updateMemberLoginData(result)
            changeFormMode()
        } catch (err) {
            toast.error(<div data-testid="failureToast">{(err as Error).message}</div>)
        }
    }

    if (mode === 'edit') {
        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }): Promise<void> => {
                    setSubmitting(true)

                    await onCreate(values)

                    if (isMounted()) {
                        setSubmitting(false)
                    }
                }}
            >
                {({ values, handleSubmit, handleReset, isSubmitting }): JSX.Element => (
                    <Form onSubmit={handleSubmit} onReset={handleReset} data-testid="form" className={styles.form}>
                        <FormPersonalData values={values} mode="update" />
                        <Button type="submit" disabled={isSubmitting} className="float-right">
                            Speichern
                        </Button>
                    </Form>
                )}
            </Formik>
        )
    }

    const renderGenger = (gender: string): string => {
        switch (gender) {
            case 'weiblich':
                return 'weiblich'
            case 'männlich':
                return 'männlich'
            case 'unbekannt':
                return 'divers'
            default:
                return 'divers'
        }
    }

    return (
        <div>
            <Row className="pb-4">
                <Col lg={4}>
                    <Row>
                        <Col>
                            <PersonalDataCard cardURL={initialValues.personalData.cardURL} />
                        </Col>
                    </Row>
                </Col>
                <Col lg={4}>
                    <Row>
                        <Col xs={6}>
                            <strong>Titel</strong>
                        </Col>
                        <Col>{initialValues.personalData.title}</Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <strong>Vorname</strong>
                        </Col>
                        <Col>{initialValues.personalData.firstName}</Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <strong>Nachname</strong>
                        </Col>
                        <Col>{initialValues.personalData.lastName}</Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <strong>Straße / HausNr.</strong>
                        </Col>
                        <Col>
                            {initialValues.personalData.street} {initialValues.personalData.streetNumber}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <strong>PLZ</strong>
                        </Col>
                        <Col>{initialValues.personalData.zip}</Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <strong>Ort</strong>
                        </Col>
                        <Col>{initialValues.personalData.city}</Col>
                    </Row>
                </Col>
                <Col lg={4}>
                    <Row>
                        <Col xs={6}>
                            <strong>Geburtsdatum</strong>
                        </Col>
                        <Col>{renderDateFromString(initialValues.personalData.dateOfBirth)}</Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <strong>Geschlecht</strong>
                        </Col>
                        <Col>{renderGenger(initialValues.personalData.gender)}</Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <strong>Telefon</strong>
                        </Col>
                        <Col>{initialValues.personalData.phone}</Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <strong>E-Mail-Adresse</strong>
                        </Col>
                        <Col>{initialValues.personalData.email}</Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <strong>Erziehungs&shy;berechtigte Person</strong>
                        </Col>
                        <Col>{initialValues.personalData.co}</Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default PersonalData
