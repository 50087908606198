import { Col, Container, Nav, Row } from 'react-bootstrap'
import styles from './PageFooter.module.scss'

const PageFooter = (): JSX.Element => {
    return (
        <footer className={styles.pageFooter}>
            <Container>
                <Row>
                    <Col>
                        <Nav className="flex-column mt-2">
                            <Nav.Item as="li" className="list-unstyled">
                                <Nav.Link href="https://www.niendorfer-tsv.de/impressum/" target="_blank">
                                    Impressum
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="list-unstyled">
                                <Nav.Link href="https://www.niendorfer-tsv.de/datenschutz/" target="_blank">
                                    Datenschutz
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col>
                        <p className="mt-2">
                            Niendorfer TSV
                            <br />
                            Sportbüro im ADYTON
                            <br />
                            Sachsenweg 78
                            <br />
                            22455 Hamburg
                            <br />
                            Telefon: <a href="tel:+49405542160">(040) 55 42 16-0</a>
                            <br />
                            E-Mail: <a href="mailto:info@niendorfer-tsv.de">info@niendorfer-tsv.de</a>
                        </p>
                    </Col>
                </Row>
            </Container>
            <div className={styles.copyright}>
                Niendorfer Turn- und Sportverein von 1919 e.V. in Hamburg | Fitness, Freizeit, Freunde
            </div>
        </footer>
    )
}

export default PageFooter
