import * as Yup from 'yup'
import { Formik } from 'formik'
import { Button, Form } from 'react-bootstrap'
import { EmailInput, FormInputLabeled, PasswordInput, useIsMounted } from 'common'
import useArvAuth from 'hooks/useArvAuth'
import styles from 'shared_components/forms.module.scss'
import { toast } from 'react-toastify'
import { useTypedStoreActions } from 'store/hooks'
import { Redirect } from 'react-router-dom'

type FormValues = {
    email: string
    password: string
}

export interface Props {
    handleShowModal: () => void
}

const FormLogin = ({ handleShowModal }: Props): JSX.Element => {
    const isMounted = useIsMounted()
    const { login } = useArvAuth()
    const setIsAuthenticated = useTypedStoreActions((state) => state.authentication.setIsAuthenticated)
    const setUserEmail = useTypedStoreActions((state) => state.authentication.setUserEmail)
    const setUserToken = useTypedStoreActions((state) => state.authentication.setUserToken)
    const setUser = useTypedStoreActions((state) => state.authentication.setUser)

    const validationSchema = Yup.object({
        email: Yup.string().email('Keine gültige E-Mail').required('Pflichtfeld'),
        password: Yup.string().required('Bitte ein Passwort eingeben'),
    })

    const initialValues: FormValues = {
        email: '',
        password: '',
    }

    const handleLogin = async (values: FormValues) => {
        try {
            const result = await login(values.email, values.password)
            if (!result.MemberLoginData.LoginData.Token?._text) {
                throw 'Got no token'
            }

            await setUserEmail(values.email)
            await setUserToken(result.MemberLoginData.LoginData.Token?._text)
            await setUser(result)
            await setIsAuthenticated(true)

            return <Redirect to={{ pathname: '/personal-data' }} />
        } catch (err) {
            toast.error(<div data-testid="failureToast">{(err as Error).message}</div>)
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }): Promise<void> => {
                setSubmitting(true)

                await handleLogin(values)

                if (isMounted()) {
                    setSubmitting(false)
                }
            }}
        >
            {({ values, handleSubmit, handleReset, isSubmitting }): JSX.Element => (
                <Form
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                    data-testid="form"
                    className={[styles.form, styles.whiteText].join(' ')}
                >
                    <FormInputLabeled
                        controlId="email"
                        label="E-Mailadresse&nbsp;*"
                        labelColumnSize={12}
                        align="start"
                        inputComponent={
                            <EmailInput
                                name="email"
                                placeholder="E-Mailadresse"
                                value={values.email}
                                className="email"
                            />
                        }
                    />
                    <FormInputLabeled
                        controlId="password"
                        label="Passwort&nbsp;*"
                        labelColumnSize={12}
                        align="start"
                        inputComponent={
                            <PasswordInput
                                name="password"
                                placeholder="Passwort"
                                value={values.password}
                                className="password"
                            />
                        }
                    />

                    <p className="text-right">
                        <a onClick={handleShowModal}>Passwort vergessen?</a>{' '}
                        <Button variant="dark" type="submit" disabled={isSubmitting}>
                            Einloggen
                        </Button>
                    </p>
                </Form>
            )}
        </Formik>
    )
}

export default FormLogin
