import styles from './H1.module.scss'

type Props = {
    children: React.ReactNode
}
const H1 = (props: Props): JSX.Element => {
    return <h1 className={styles.headline}>{props.children}</h1>
}

export default H1
