import * as Sentry from '@sentry/react'
import { H2 } from 'common'
import { useInterval } from 'common/hooks/useInterval'
import LoggedInHeader from 'components/LoggedInHeader'
import useArvAuth from 'hooks/useArvAuth'
import * as React from 'react'
import { useEffect } from 'react'
import { Accordion, Alert, Button, Card, Col, Container, Nav, Navbar, Row } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { InitialValuesSportData } from 'shared_components/FormSportData'
import { InitialValuesSportDataSimple } from 'shared_components/FormSportData/InitialValuesSportData'
import {
    Attendee,
    SportDataSections,
    SportDataType,
    SportEndingSections,
} from 'shared_components/FormSportData/SportData'
import { useTypedStoreActions, useTypedStoreState } from 'store/hooks'
import { LoadingState } from 'types/status'
import styles from './MemberData.module.scss'
import { FormMode } from './MemberDataTypes'
import ActiveMembershipTable from './components/ActiveMembershipTable'
import ActiveSectionsData from './components/ActiveSectionsData'
import CancelMembership from './components/CancelMembership'
import ChangePassword from './components/ChangePassword'
import CourseData from './components/CourseData'
import AccordionToggle from './components/DataAccordion'
import PaymentData from './components/PaymentData'
import PersonalData from './components/PersonalData'
import SportData from './components/SportData'

const MemberData = (): JSX.Element => {
    const { refreshToken } = useArvAuth()
    const setIsAuthenticated = useTypedStoreActions((state) => state.authentication.setIsAuthenticated)
    const userToken = useTypedStoreState((state) => state.authentication.user?.MemberLoginData.LoginData.Token)
    const memberData = useTypedStoreState((state) => state.authentication.user?.MemberLoginData.MemberData)
    const userEmail = useTypedStoreState((state) => state.authentication.userEmail)

    const personalData = useTypedStoreState((state) => state.personalData.personalData)
    const getPersonalData = useTypedStoreActions((state) => state.personalData.getPersonalData)
    const getPersonalNames = useTypedStoreActions((state) => state.personalData.getPersonalNames)

    const memberIndex = useTypedStoreState((state) => state.memberIndexData.index)
    const setMemberIndex = useTypedStoreActions((state) => state.memberIndexData.setIndex)

    const paymentData = useTypedStoreState((state) => state.paymentData.paymentData)
    const getPaymentData = useTypedStoreActions((state) => state.paymentData.getPaymentData)

    const billingData = useTypedStoreState((state) => state.billingData.billingData)
    const getBillingData = useTypedStoreActions((state) => state.billingData.getBillingData)

    const sportData = useTypedStoreState((state) => state.sportData.sportData)
    const getSportData = useTypedStoreActions((state) => state.sportData.getSportData)

    const courseData = useTypedStoreState((state) => state.courseData.courseData)
    const getCourseData = useTypedStoreActions((state) => state.courseData.getCourseData)

    const [loadingState, setLoadingState] = React.useState<LoadingState>('loading')
    const [modePersonalData, setModePersonalData] = React.useState<FormMode>(FormMode.VIEW)
    const [modePaymentData, setModePaymentData] = React.useState<FormMode>(FormMode.VIEW)

    const [sportDataValues, setSportDataValues] = React.useState<SportDataSections>({
        ...InitialValuesSportDataSimple,
    })
    const [endingDataValues, setendingDataValues] = React.useState<SportEndingSections>({
        ...InitialValuesSportData.endingData,
    })
    const attendee: Attendee = {
        attendee: 'member',
        attendeeMember: {
            memberId: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            gender: '',
        },
    }

    const formValues: SportDataType = {
        sportData: { ...sportDataValues, ...attendee },
        endingData: { ...endingDataValues },
    }

    let memberSportData: SportDataType = InitialValuesSportData

    const changeFormMode = (mode: FormMode, form: string) => {
        switch (form) {
            case 'personalData':
                setModePersonalData(mode)
                break
            case 'paymentData':
                setModePaymentData(mode)
                break
        }
    }

    const submitSportForm = () => {
        const btn = document.getElementById('submit-sport-form')
        btn?.click()
    }

    const changeSectionStatus = (section: string, join: boolean, parent?: string): SportDataSections => {
        let newSportDataValues = sportDataValues

        if (parent) {
            let subSectionData = null
            switch (parent) {
                case 'onewheelAddons':
                    subSectionData = sportDataValues.onewheelAddons
                    break
                case 'athleticsAddons':
                    subSectionData = sportDataValues.athleticsAddons
                    break
            }

            if (subSectionData !== null) {
                subSectionData = { ...subSectionData, ...{ [section]: join } }
                newSportDataValues = { ...sportDataValues, ...{ [parent]: subSectionData } }
            }
        } else {
            newSportDataValues = { ...sportDataValues, ...{ [section]: join } }
        }

        setSportDataValues(newSportDataValues)
        // TODO setendingDataValues
        submitSportForm()
        return newSportDataValues
    }

    useEffect((): void => {
        const fetchData = async (): Promise<void> => {
            try {
                await getPersonalData()
                await getPaymentData()
                await getSportData()
                await getBillingData()
                await getCourseData()

                if (sportData !== null) {
                    memberSportData = sportData
                }

                setLoadingState('succeeded')
            } catch (err) {
                setLoadingState('failed')
            }
        }

        fetchData()
    }, [
        getPersonalData,
        getPaymentData,
        getSportData,
        modePersonalData,
        getBillingData,
        getCourseData,
        modePaymentData,
        memberIndex,
    ])

    useEffect((): void => {
        if (sportData !== null) {
            setSportDataValues(sportData.sportData)
            setendingDataValues(sportData.endingData)
        }
    }, [setSportDataValues, setendingDataValues, sportData])

    useInterval(async () => {
        try {
            if (userToken?._text) {
                await refreshToken(userEmail, userToken?._text)
            }
        } catch {
            Sentry.captureException(`Session Refresh failed`, { level: Sentry.Severity.Warning })

            await setIsAuthenticated(false)
            return <Redirect to={{ pathname: '/' }} />
        }
    }, 4 * 60000) // alle vier minuten refreshen

    if (loadingState === 'loading') {
        return <Alert variant="info">Einen Moment, bitte. Ihre Daten werden abgerufen.</Alert>
    }

    if (loadingState === 'failed') {
        return <Alert variant="danger">Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut.</Alert>
    }

    // if (!personalData || !paymentData || !sportData || !getBillingData) {
    if (!personalData || !paymentData || !getBillingData) {
        return <Alert variant="danger">Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut.</Alert>
    }

    const fetchPersonalData = (index: number) => {
        setMemberIndex(index)
    }

    const personalNames = getPersonalNames()

    let cardClassName = 'mb-4'
    if (personalNames.length > 1) {
        cardClassName += ' ' + styles.mb4
    }

    return (
        <>
            <LoggedInHeader />

            <Container>
                <Row>
                    <Col>
                        {personalNames.length > 1 && (
                            <Navbar bg="light" className={styles.navbar}>
                                <Container fluid>
                                    <Nav navbarScroll>
                                        {personalNames.map((name: string, index: number) => {
                                            if (index === memberIndex) {
                                                return (
                                                    <Navbar.Text key={index} className={styles.navbarText}>
                                                        <span className={styles.navbarHighlight}>{name}</span>
                                                    </Navbar.Text>
                                                )
                                            } else {
                                                return (
                                                    <Nav.Link
                                                        onClick={() => {
                                                            fetchPersonalData(index)
                                                        }}
                                                        key={index}
                                                        className={styles.navbarLink}
                                                    >
                                                        {name}
                                                    </Nav.Link>
                                                )
                                            }
                                        })}
                                    </Nav>
                                </Container>
                            </Navbar>
                        )}
                        <Card className={cardClassName}>
                            <Card.Body>
                                <Accordion defaultActiveKey="0">
                                    <Card className="border-0">
                                        <AccordionToggle
                                            isDefault={true}
                                            eventKey="0"
                                            headline={`Persönliche Daten - ${personalData.personalData.lastName} - #${personalData.personalData.ntsvMemberId}.${personalData.personalData.ntsvLfdNumber}`}
                                        />
                                        <Accordion.Collapse eventKey="0">
                                            <Card className="border-0">
                                                <Card.Body>
                                                    <PersonalData
                                                        mode={modePersonalData}
                                                        initialValues={personalData}
                                                        changeFormMode={() =>
                                                            changeFormMode(FormMode.VIEW, 'personalData')
                                                        }
                                                    />
                                                    <div className="text-right">
                                                        {modePersonalData === FormMode.VIEW && (
                                                            <Button
                                                                onClick={() =>
                                                                    changeFormMode(FormMode.EDIT, 'personalData')
                                                                }
                                                            >
                                                                Bearbeiten
                                                            </Button>
                                                        )}
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card className="border-0">
                                        <AccordionToggle eventKey="1" headline={`Passwort ändern`} />
                                        <Accordion.Collapse eventKey="1">
                                            <Card className="border-0">
                                                <Card.Body>
                                                    <ChangePassword userToken={userToken?._text} />
                                                </Card.Body>
                                            </Card>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card className="border-0">
                                        <AccordionToggle eventKey="2" headline="Bankdaten" />
                                        <Accordion.Collapse eventKey="2">
                                            <Card className="border-0">
                                                <Card.Body>
                                                    <PaymentData
                                                        mode={modePaymentData}
                                                        initialValues={paymentData}
                                                        changeFormMode={() =>
                                                            changeFormMode(FormMode.VIEW, 'paymentData')
                                                        }
                                                    />
                                                    <div className="text-right">
                                                        {modePaymentData === FormMode.VIEW && (
                                                            <Button
                                                                onClick={() =>
                                                                    changeFormMode(FormMode.EDIT, 'paymentData')
                                                                }
                                                            >
                                                                Bearbeiten
                                                            </Button>
                                                        )}
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card className="border-0">
                                        <AccordionToggle eventKey="4" headline="Mitgliedschaft & Beiträge" />
                                        <Accordion.Collapse eventKey="4">
                                            <Card className="border-0">
                                                <Card.Body>
                                                    <ActiveMembershipTable
                                                        billingsData={billingData}
                                                        memberData={memberData}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card className="border-0">
                                        <AccordionToggle eventKey="3" headline="Sparten" />
                                        <Accordion.Collapse eventKey="3">
                                            <>
                                                <Card className="border-0">
                                                    <Card.Body>
                                                        <div>
                                                            <H2>Aktive Sparten</H2>
                                                            <p>
                                                                Hier können Sie aus aktiven Sparten austreten. Der
                                                                Austritt aus einer Sparte ist möglich mit einer Frist
                                                                von einem Monat zum Quartalsende.
                                                            </p>
                                                        </div>
                                                        <ActiveSectionsData
                                                            onLeave={changeSectionStatus}
                                                            initialValues={formValues}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0">
                                                    <Card.Body>
                                                        <SportData
                                                            initialValues={formValues}
                                                            sportData={memberSportData}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0">
                                                    <Card.Body>
                                                        <CancelMembership />
                                                    </Card.Body>
                                                </Card>
                                            </>
                                        </Accordion.Collapse>
                                    </Card>

                                    {formValues.sportData.freeAthletics && courseData && (
                                        <Card className="border-0">
                                            <AccordionToggle
                                                eventKey="5"
                                                headline="Gruppen/Kinderturngruppen"
                                                subline="Hinweis: Die Gruppenanmeldung befindet sich in der Testphase - verbindliche Anmeldungen bitte aktuell unbedingt über SPORTMEO tätigen."
                                            />
                                            <Accordion.Collapse eventKey="5">
                                                <>
                                                    <Card className="border-0">
                                                        <Card.Body>
                                                            <CourseData courses={courseData} />
                                                        </Card.Body>
                                                    </Card>
                                                </>
                                            </Accordion.Collapse>
                                        </Card>
                                    )}
                                </Accordion>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default MemberData
