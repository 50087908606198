import {
    Checkbox,
    DateInput,
    EmailInput,
    FormInputLabeled,
    H2,
    NewTabIcon,
    NumberInput,
    RadioButtonGroup,
    SelectField,
    TextInput,
    TextMuted,
} from 'common'
import SquareDown from 'common/atoms/Icons/SquareDown'
import { Accordion, Card, Col, Form, Row } from 'react-bootstrap'
import FieldInfo from 'shared_components/FieldInfo'

import determineAge from '../determineAge'
import { SportDataType } from './SportData'
import TanzportTooltip from './TanzportTooltip'

interface Props {
    values: SportDataType
    sportData: SportDataType
    initialValues?: SportDataType
    dateOfBirth?: string
    today: Date
}

const isAdult = (age: number): boolean => age >= 18
const hasAdyton = (age: number): boolean => age >= 14
const needsAttendee = (age: number): boolean => age < 4

const FormSportData = ({ values, initialValues, sportData, dateOfBirth, today }: Props): JSX.Element => {
    const age = determineAge(today, dateOfBirth)

    return (
        <>
            <H2>Abteilungen/Sparten</H2>
            <p>
                Übersicht über unser{' '}
                <a href="https://www.niendorfer-tsv.de/sportsuche/" target="_blank" rel="noopener noreferrer">
                    Sportprogramm
                </a>{' '}
                sowie all unseren{' '}
                <a href="https://www.niendorfer-tsv.de/beitraege/" target="_blank" rel="noopener noreferrer">
                    Beiträgen
                </a>{' '}
                (bitte Verlinkung folgen)
            </p>

            <Row>
                {!initialValues?.sportData.aikido && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.aikido"
                            inputComponent={
                                <Checkbox
                                    label="Aikido"
                                    name="sportData.aikido"
                                    value={values.sportData.aikido}
                                    disabled={values.endingData.aikido !== undefined}
                                    className="aikido"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.baseball && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.baseball"
                            inputComponent={
                                <Checkbox
                                    label="Baseball"
                                    name="sportData.baseball"
                                    value={values.sportData.baseball}
                                    disabled={values.endingData.baseball !== undefined}
                                    className="baseball"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.basketball && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.basketball"
                            inputComponent={
                                <Checkbox
                                    label="Basketball"
                                    name="sportData.basketball"
                                    value={values.sportData.basketball}
                                    disabled={values.endingData.basketball !== undefined}
                                    className="basketball"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.badminton && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.badminton"
                            inputComponent={
                                <Checkbox
                                    label="Badminton"
                                    name="sportData.badminton"
                                    value={values.sportData.badminton}
                                    disabled={values.endingData.badminton !== undefined}
                                    className="badminton"
                                />
                            }
                        />
                    </Col>
                )}
                <Col xs={6} md={4} lg={3}>
                    <Accordion>
                        <>
                            <Accordion.Toggle as={Form.Label} eventKey="0" className="accordion-toggle-hover">
                                <SquareDown width={14} /> Einrad
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card bg="light" className="mt-2 mb-4">
                                    <Card.Body>
                                        <>
                                            <Row>
                                                {!initialValues?.sportData.onewheelAddons.onewheel && (
                                                    <Col>
                                                        <FormInputLabeled
                                                            controlId="sportData.onewheelAddons.onewheel"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Einrad"
                                                                    name="sportData.onewheelAddons.onewheel"
                                                                    value={values.sportData.onewheelAddons.onewheel}
                                                                    disabled={
                                                                        values.endingData.onewheelAddons.onewheel !==
                                                                        undefined
                                                                    }
                                                                    className="onewheelAddonsOnewheel"
                                                                />
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.onewheelAddons.hockey && (
                                                    <Col>
                                                        <FormInputLabeled
                                                            controlId="sportData.onewheelAddons.hockey"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Einradhockey"
                                                                    name="sportData.onewheelAddons.hockey"
                                                                    value={values.sportData.onewheelAddons.hockey}
                                                                    disabled={
                                                                        values.endingData.onewheelAddons.hockey !==
                                                                        undefined
                                                                    }
                                                                    className="onewheelAddonsOneHockey"
                                                                />
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        </>
                                    </Card.Body>
                                </Card>
                            </Accordion.Collapse>
                        </>
                    </Accordion>
                </Col>
                {!initialValues?.sportData.soccer && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.soccer"
                            inputComponent={
                                <Checkbox
                                    label="Fußball"
                                    name="sportData.soccer"
                                    value={values.sportData.soccer}
                                    disabled={values.endingData.soccer !== undefined}
                                    className="soccer"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.handball && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.handball"
                            inputComponent={
                                <Checkbox
                                    label="Handball"
                                    name="sportData.handball"
                                    value={values.sportData.handball}
                                    disabled={values.endingData.handball !== undefined}
                                    className="handball"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.judo && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.judo"
                            inputComponent={
                                <Checkbox
                                    label="Judo"
                                    name="sportData.judo"
                                    value={values.sportData.judo}
                                    disabled={values.endingData.judo !== undefined}
                                    className="judo"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.jujutsu && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.jujutsu"
                            inputComponent={
                                <Checkbox
                                    label="JuJutsu"
                                    name="sportData.jujutsu"
                                    value={values.sportData.jujutsu}
                                    disabled={values.endingData.jujutsu !== undefined}
                                    className="jujutsu"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.karate && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.karate"
                            inputComponent={
                                <Checkbox
                                    label="Karate"
                                    name="sportData.karate"
                                    value={values.sportData.karate}
                                    disabled={values.endingData.karate !== undefined}
                                    className="karate"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.kungfu && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.kungfu"
                            inputComponent={
                                <Checkbox
                                    label="Kung-Fu"
                                    name="sportData.kungfu"
                                    value={values.sportData.kungfu}
                                    disabled={values.endingData.kungfu !== undefined}
                                    className="kungfu"
                                />
                            }
                        />
                    </Col>
                )}
                <Col xs={6} md={4} lg={3}>
                    <Accordion>
                        <>
                            <Accordion.Toggle as={Form.Label} eventKey="0" className="accordion-toggle-hover">
                                <SquareDown width={14} /> Leichtathletik
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card bg="light" className="mt-2 mb-4">
                                    <Card.Body>
                                        <>
                                            {!initialValues?.sportData.athleticsAddons.athletics && (
                                                <FormInputLabeled
                                                    controlId="sportData.athleticsAddons.athletics"
                                                    inputComponent={
                                                        <Checkbox
                                                            label="Leichtathletik"
                                                            name="sportData.athleticsAddons.athletics"
                                                            value={values.sportData.athleticsAddons.athletics}
                                                            disabled={
                                                                values.endingData.athleticsAddons.athletics !==
                                                                undefined
                                                            }
                                                            className="athleticsAddonsAthletics"
                                                        />
                                                    }
                                                />
                                            )}
                                            {!initialValues?.sportData.athleticsAddons.walking && (
                                                <FormInputLabeled
                                                    controlId="sportData.athleticsAddons.walking"
                                                    inputComponent={
                                                        <Checkbox
                                                            label="Walken"
                                                            name="sportData.athleticsAddons.walking"
                                                            value={values.sportData.athleticsAddons.walking}
                                                            disabled={
                                                                values.endingData.athleticsAddons.walking !== undefined
                                                            }
                                                            className="athleticsAddonsWalking"
                                                        />
                                                    }
                                                />
                                            )}
                                        </>
                                    </Card.Body>
                                </Card>
                            </Accordion.Collapse>
                        </>
                    </Accordion>
                </Col>
                {!initialValues?.sportData.chess && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.chess"
                            inputComponent={
                                <Checkbox
                                    label="Schach"
                                    name="sportData.chess"
                                    value={values.sportData.chess}
                                    disabled={values.endingData.chess !== undefined}
                                    className="chess"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.swimming && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.swimming"
                            inputComponent={
                                <Checkbox
                                    label={
                                        <>
                                            Schwimmen
                                            <FieldInfo trigger="click" tipId="memberId">
                                                <div>
                                                    <p>
                                                        Hier handelt es sich um das Angebot unserer Schwimmabteilung,
                                                        nicht um unserer Schwimmschule Niendorf.
                                                    </p>
                                                    <p>
                                                        Die Anmeldung für die Schwimmschule erfolgt{' '}
                                                        <a
                                                            href="https://kursbuchungen.niendorfer-tsv.de"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            hier
                                                        </a>
                                                    </p>
                                                </div>
                                            </FieldInfo>
                                        </>
                                    }
                                    name="sportData.swimming"
                                    value={values.sportData.swimming}
                                    disabled={values.endingData.swimming !== undefined}
                                    className="swimming"
                                />
                            }
                        />
                    </Col>
                )}
                <Col xs={12} md={12} lg={6}>
                    <Accordion>
                        <>
                            <Accordion.Toggle as={Form.Label} eventKey="0" className="accordion-toggle-hover">
                                <SquareDown width={14} /> Tanzsport
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card bg="light" className="mt-2 mb-4">
                                    <Card.Body>
                                        <>
                                            <Row>
                                                {!initialValues?.sportData.danceAddon_ballett && (
                                                    <Col xs={12} md={6}>
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_ballett"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Ballett"
                                                                    name="sportData.danceAddon_ballett"
                                                                    value={values.sportData.danceAddon_ballett}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_ballett !==
                                                                        undefined
                                                                    }
                                                                    className="dancingBallett"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_bellydance && (
                                                    <Col xs={12} md={6}>
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_bellydance"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Bauchtanz"
                                                                    name="sportData.danceAddon_bellydance"
                                                                    value={values.sportData.danceAddon_bellydance}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_bellydance !==
                                                                        undefined
                                                                    }
                                                                    className="dancingBellydance"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_breakdance && (
                                                    <Col xs={12} md={6}>
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_breakdance"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Breakdance"
                                                                    name="sportData.danceAddon_breakdance"
                                                                    value={values.sportData.danceAddon_breakdance}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_breakdance !==
                                                                        undefined
                                                                    }
                                                                    className="dancingBreakdance"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_hiphop && (
                                                    <Col xs={12} md={6}>
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_hiphop"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Hip Hop"
                                                                    name="sportData.danceAddon_hiphop"
                                                                    value={values.sportData.danceAddon_hiphop}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_hiphop !==
                                                                        undefined
                                                                    }
                                                                    className="dancingHiphop"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_creativeChildrendance && (
                                                    <Col xs={12} md={6}>
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_creativeChildrendance"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Kreativer Kindertanz"
                                                                    name="sportData.danceAddon_creativeChildrendance"
                                                                    value={
                                                                        values.sportData
                                                                            .danceAddon_creativeChildrendance
                                                                    }
                                                                    disabled={
                                                                        values.endingData
                                                                            ?.danceAddon_creativeChildrendance !==
                                                                        undefined
                                                                    }
                                                                    className="dancingcreativeChildrendance"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_linedance && (
                                                    <Col xs={12} md={6}>
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_linedance"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Linedance"
                                                                    name="sportData.danceAddon_linedance"
                                                                    value={values.sportData.danceAddon_linedance}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_linedance !==
                                                                        undefined
                                                                    }
                                                                    className="dancingLinedance"
                                                                />
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_pairdance && (
                                                    <Col xs={12} md={6}>
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_pairdance"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Paartanz"
                                                                    name="sportData.danceAddon_pairdance"
                                                                    value={values.sportData.danceAddon_pairdance}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_pairdance !==
                                                                        undefined
                                                                    }
                                                                    className="dancingPairdance"
                                                                />
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_tapdance && (
                                                    <Col xs={12} md={6}>
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_tapdance"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Stepptanz"
                                                                    name="sportData.danceAddon_tapdance"
                                                                    value={values.sportData.danceAddon_tapdance}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_tapdance !==
                                                                        undefined
                                                                    }
                                                                    className="dancingTapdance"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_contemporary && (
                                                    <Col xs={12} md={6}>
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_contemporary"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Jazz / Modern+Contemporary"
                                                                    name="sportData.danceAddon_contemporary"
                                                                    value={values.sportData.danceAddon_contemporary}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_contemporary !==
                                                                        undefined
                                                                    }
                                                                    className="dancingContemporary"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        </>
                                    </Card.Body>
                                </Card>
                            </Accordion.Collapse>
                        </>
                    </Accordion>
                </Col>
                {!initialValues?.sportData.tableTennis && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.tableTennis"
                            inputComponent={
                                <Checkbox
                                    label="Tischtennis"
                                    name="sportData.tableTennis"
                                    value={values.sportData.tableTennis}
                                    disabled={values.endingData.tableTennis !== undefined}
                                    className="tableTennis"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.theatre && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.theatre"
                            inputComponent={
                                <Checkbox
                                    label="Theater / Laienspiel"
                                    name="sportData.theatre"
                                    value={values.sportData.theatre}
                                    disabled={values.endingData.theatre !== undefined}
                                    className="theatre"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.volleyball && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.volleyball"
                            inputComponent={
                                <Checkbox
                                    label="Volleyball"
                                    name="sportData.volleyball"
                                    value={values.sportData.volleyball}
                                    disabled={values.endingData.volleyball !== undefined}
                                    className="volleyball"
                                />
                            }
                        />
                    </Col>
                )}
                {hasAdyton(age) && !initialValues?.sportData.adyton && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.adyton"
                            inputComponent={
                                <Checkbox
                                    label={
                                        <>
                                            Adyton
                                            <FieldInfo trigger="click" tipId="memberId">
                                                <div>
                                                    <p>
                                                        Die Aufnahmegebühren sowie sonstige Gebühren (Fitnesscheck etc)
                                                        sind mit dem ersten Beitragseinzug fällig. Die jeweilige Höhe
                                                        des Beitrags, der Aufnahmegebühr und sonstige Gebühren werden
                                                        nach der im Sportprogramm enthaltenen Beitrags- und
                                                        Gebührenauflistung geregelt und wird nicht gesondert mitgeteilt.
                                                    </p>
                                                </div>
                                            </FieldInfo>
                                            <a
                                                href="https://www.niendorfer-tsv.de/beitraege/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <NewTabIcon />
                                            </a>
                                        </>
                                    }
                                    name="sportData.adyton"
                                    value={values.sportData.adyton}
                                    disabled={values.endingData.adyton !== undefined}
                                    className="adyton"
                                />
                            }
                        />
                        {values.sportData.adyton && (
                            <FormInputLabeled
                                controlId="sportData.adytonAddon"
                                inputComponent={
                                    <RadioButtonGroup
                                        name="sportData.adytonAddon"
                                        options={[
                                            {
                                                value: 'gold',
                                                label: 'Gold (Fitness/Kurse/Wellness)',
                                            },
                                            {
                                                value: 'silber',
                                                label: 'Silber (Fitness/Kurse)',
                                            },
                                            {
                                                value: 'bronze',
                                                label: 'Bronze (Wellness)',
                                            },
                                        ]}
                                        value={values.sportData.adytonAddon}
                                        disabled={values.endingData.adyton !== undefined || sportData?.sportData.adyton}
                                        className="adytonAddon"
                                    />
                                }
                            />
                        )}
                    </Col>
                )}
                {!initialValues?.sportData.tennis && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.tennis"
                            inputComponent={
                                <Checkbox
                                    label={
                                        <>
                                            Tennis
                                            <FieldInfo trigger="click" tipId="memberId">
                                                <div>
                                                    <p>
                                                        Leider sind unsere Tennis Schnupperplätze im Moment ausgebucht.
                                                        Nachfragen bitte direkt über unsere Tennisschule:
                                                        info@professional-tennis.de oder Tel. 0176 208 24 959 - Luisa
                                                        Barth
                                                    </p>
                                                </div>
                                            </FieldInfo>
                                            <a
                                                href="https://www.niendorfer-tsv.de/mitgliedschaft/zusatzbeitraege-tennis.html"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <NewTabIcon />
                                            </a>
                                        </>
                                    }
                                    name="sportData.tennis"
                                    value={values.sportData.tennis}
                                    disabled={values.endingData.tennis !== undefined}
                                    className="tennis"
                                />
                            }
                        />
                        {values.sportData.tennis && isAdult(age) && (
                            <FormInputLabeled
                                controlId="sportData.tennisAddonAdult"
                                inputComponent={
                                    <RadioButtonGroup
                                        name="sportData.tennisAddonAdult"
                                        options={[
                                            {
                                                value: 'none',
                                                label: 'kein Zusatz',
                                            },
                                            {
                                                value: 'wellness',
                                                label: 'mit Wellness',
                                            },
                                            // {
                                            //     value: 'schnupperSommer',
                                            //     label: 'Schnuppermitgliedschaft Sommer (Mai bis Sept.)',
                                            // },
                                        ]}
                                        value={values.sportData.tennisAddonAdult}
                                        disabled={values.endingData.tennis !== undefined || sportData?.sportData.tennis}
                                        className="tennisAddonAdult"
                                    />
                                }
                            />
                        )}
                        {/* {values.sportData.tennis && !isAdult(age) && (
                        <FormInputLabeled
                            controlId="sportData.tennisAddonChild"
                            inputComponent={
                                <RadioButtonGroup
                                    name="sportData.tennisAddonChild"
                                    options={[
                                        {
                                            value: 'none',
                                            label: 'Kein Zusatz',
                                        },
                                        {
                                            value: 'schnupperSommer',
                                            label: 'Schnuppermitgliedschaft Sommer (Mai bis Sept.)',
                                        },
                                        {
                                            value: 'schnupperWinter',
                                            label: 'Schnuppermitgliedschaft Winter (Okt. bis  Apr.)',
                                        },
                                    ]}
                                    value={values.sportData.tennisAddonChild}
                                    disabled={values.endingData.tennis !== undefined}
                                    className="tennisAddonChild"
                                />
                            }
                        />
                    )} */}
                    </Col>
                )}
            </Row>

            <Row>
                {!initialValues?.sportData.freeAthletics && (
                    <Col>
                        <FormInputLabeled
                            controlId="sportData.freeAthletics"
                            inputComponent={
                                <Checkbox
                                    label="Turnen, Freizeit- und Gesundheitssport (z.B. Kinderturnen für Kinder über 4 Jahre, Seniorensport, BBP)"
                                    name="sportData.freeAthletics"
                                    value={values.sportData.freeAthletics}
                                    disabled={values.endingData.freeAthletics !== undefined}
                                    className="freeAthletics"
                                />
                            }
                        />
                    </Col>
                )}
            </Row>

            <Row>
                <Col>
                    <TextMuted>
                        <p>
                            Folgende{' '}
                            <a
                                href="https://www.niendorfer-tsv.de/beitraege/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Sportangebote der TFG
                            </a>{' '}
                            sind zusatzbeitragspflichtig. Bei mehrfacher, wöchentlicher Teilnahme ist auch ein
                            Mehrfaches des Zusatzbeitrags zu entrichten:
                        </p>
                    </TextMuted>
                </Col>
            </Row>

            {isAdult(age) && (
                <Row>
                    {!initialValues?.sportData.osteoporose && (
                        <Col xs={6} md={4} lg={3}>
                            <FormInputLabeled
                                controlId="sportData.osteoporose"
                                inputComponent={
                                    <Checkbox
                                        label="Osteoporose"
                                        name="sportData.osteoporose"
                                        value={values.sportData.osteoporose}
                                        disabled={values.endingData.osteoporose !== undefined}
                                        className="osteoporose"
                                    />
                                }
                            />
                        </Col>
                    )}
                    {!initialValues?.sportData.backFit && (
                        <Col xs={6} md={4} lg={3}>
                            <FormInputLabeled
                                controlId="sportData.backFit"
                                inputComponent={
                                    <Checkbox
                                        label="Rückenfit"
                                        name="sportData.backFit"
                                        value={values.sportData.backFit}
                                        disabled={values.endingData.backFit !== undefined}
                                        className="backFit"
                                    />
                                }
                            />
                        </Col>
                    )}
                    {!initialValues?.sportData.yoga && (
                        <Col xs={6} md={4} lg={3}>
                            <FormInputLabeled
                                controlId="sportData.yoga"
                                inputComponent={
                                    <Checkbox
                                        label="Yoga"
                                        name="sportData.yoga"
                                        value={values.sportData.yoga}
                                        disabled={values.endingData.yoga !== undefined}
                                        className="yoga"
                                    />
                                }
                            />
                        </Col>
                    )}
                    {!initialValues?.sportData.lungSport && (
                        <Col xs={6} md={4} lg={3}>
                            <FormInputLabeled
                                controlId="sportData.lungSport"
                                inputComponent={
                                    <Checkbox
                                        label="Lungensport ohne Verordnung"
                                        name="sportData.lungSport"
                                        value={values.sportData.lungSport}
                                        disabled={values.endingData.lungSport !== undefined}
                                        className="lungSport"
                                    />
                                }
                            />
                        </Col>
                    )}
                    {!initialValues?.sportData.krebsSport && (
                        <Col xs={6} md={4} lg={3}>
                            <FormInputLabeled
                                controlId="sportData.krebsSport"
                                inputComponent={
                                    <Checkbox
                                        label="Krebsnachsorge ohne Verordnung"
                                        name="sportData.krebsSport"
                                        value={values.sportData.krebsSport}
                                        disabled={values.endingData.krebsSport !== undefined}
                                        className="krebsSport"
                                    />
                                }
                            />
                        </Col>
                    )}
                </Row>
            )}

            {!isAdult(age) && (
                <Row>
                    {!initialValues?.sportData.childAthletics && needsAttendee(age) && (
                        <Col xs={6} md={4} lg={3}>
                            <FormInputLabeled
                                controlId="sportData.childAthletics"
                                inputComponent={
                                    <Checkbox
                                        label="Eltern-Kind-Turnen (für Kinder bis 4 Jahren)"
                                        name="sportData.childAthletics"
                                        value={values.sportData.childAthletics}
                                        disabled={values.endingData.childAthletics !== undefined}
                                        className="childAthletics"
                                        data-testid={`formCheckbox_sportData.childAthletics`}
                                    />
                                }
                            />
                        </Col>
                    )}
                    {!initialValues?.sportData.trampoline && (
                        <Col xs={6} md={4} lg={3}>
                            <FormInputLabeled
                                controlId="sportData.trampoline"
                                inputComponent={
                                    <Checkbox
                                        label="Trampolin"
                                        name="sportData.trampoline"
                                        value={values.sportData.trampoline}
                                        disabled={values.endingData.trampoline !== undefined}
                                        className="trampoline"
                                    />
                                }
                            />
                        </Col>
                    )}
                </Row>
            )}

            <Row>
                {!initialValues?.sportData.sportgymnastik && (
                    <Col xs={6} md={4} lg={3}>
                        <FormInputLabeled
                            controlId="sportData.sportgymnastik"
                            inputComponent={
                                <Checkbox
                                    label="Rhythmische Sportgymnastik"
                                    name="sportData.sportgymnastik"
                                    value={values.sportData.sportgymnastik}
                                    disabled={values.endingData.sportgymnastik !== undefined}
                                    className="sportgymnastik"
                                />
                            }
                        />
                    </Col>
                )}
            </Row>

            {values.sportData.childAthletics && (
                <Row>
                    <Col>
                        {needsAttendee(age) && (
                            <FormInputLabeled
                                controlId="sportData.attendee"
                                inputComponent={
                                    <RadioButtonGroup
                                        name="sportData.attendee"
                                        options={[
                                            {
                                                value: 'member',
                                                label: 'Begleitperson ist bereits Mitglied',
                                            },
                                            {
                                                value: 'new',
                                                label: 'Begleitperson ist noch kein Mitglied',
                                            },
                                        ]}
                                        value={values.sportData.attendee}
                                        className="attendee"
                                        data-testid="formRadioButton_sportData.attendee_member"
                                    />
                                }
                            />
                        )}
                        {needsAttendee(age) && values.sportData.attendee === 'new' && (
                            <Row>
                                <Col md={6} className="order-md-last">
                                    <p>
                                        Bei unseren jüngsten Mitgliedern ist es erforderlich, dass für das Sportangebot
                                        Eltern-Kind-Turnen (bis 4 Jahre) eine Begleitperson im Niendorfer TSV erfasst
                                        ist. Für die Aufnahme dieser Begleitperson wird keine Aufnahmegebühr berechnet.
                                        Diese Mitgliedschaft endet automatisch in dem Quartal, in dem Ihr Kind 4 Jahre
                                        alt wird.
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="Vorname&nbsp;*"
                                            controlId="sportData.attendeeNew.firstName"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeNew.firstName"
                                                    placeholder="Vorname"
                                                    value={values.sportData.attendeeNew?.firstName}
                                                    className="firstName"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="Nachname&nbsp;*"
                                            controlId="sportData.attendeeNew.lastName"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeNew.lastName"
                                                    placeholder="Nachname"
                                                    value={values.sportData.attendeeNew?.lastName}
                                                    className="lastName"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="Geburtsdatum&nbsp;*"
                                            controlId="sportData.attendeeNew.dateOfBirth"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <DateInput
                                                    name="sportData.attendeeNew.dateOfBirth"
                                                    placeholder="Nachname"
                                                    value={values.sportData.attendeeNew?.dateOfBirth}
                                                    className="dateOfBirth"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="Geschlecht&nbsp;*"
                                            controlId="sportData.attendeeNew.gender"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <SelectField
                                                    name="sportData.attendeeNew.gender"
                                                    placeholder="Geschlecht"
                                                    value={values.sportData.attendeeNew?.gender}
                                                    className="gender"
                                                    options={[
                                                        { value: '', label: 'Bitte wählen' },
                                                        { value: 'weiblich', label: 'Weiblich' },
                                                        { value: 'männlich', label: 'Männlich' },
                                                        { value: 'unbekannt', label: 'Divers' },
                                                    ]}
                                                    data-testid="formDateInput_sportData.attendeeNew.gender"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="Straße&nbsp;*"
                                            controlId="sportData.attendeeNew.street"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeNew.street"
                                                    placeholder="Straße"
                                                    value={values.sportData.attendeeNew?.street}
                                                    className="street"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="Hausnummer&nbsp;*"
                                            controlId="sportData.attendeeNew.streetNumber"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeNew.streetNumber"
                                                    placeholder="Hausnummer"
                                                    value={values.sportData.attendeeNew?.streetNumber}
                                                    className="streetNumber"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="PLZ&nbsp;*"
                                            controlId="sportData.attendeeNew.zip"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <NumberInput
                                                    name="sportData.attendeeNew.zip"
                                                    placeholder="PLZ"
                                                    value={values.sportData.attendeeNew?.zip}
                                                    className="zip"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="Ort&nbsp;*"
                                            controlId="sportData.attendeeNew.city"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeNew.city"
                                                    placeholder="Ort"
                                                    value={values.sportData.attendeeNew?.city}
                                                    className="city"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="E-Mail Adresse&nbsp;*"
                                            controlId="sportData.attendeeNew.email"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <EmailInput
                                                    name="sportData.attendeeNew.email"
                                                    placeholder="Ort"
                                                    value={values.sportData.attendeeNew?.email}
                                                    className="email"
                                                />
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}
                        {needsAttendee(age) && values.sportData.attendee === 'member' && (
                            <Row>
                                <Col md={6} className="order-md-last">
                                    <p>
                                        Bei unseren jüngsten Mitgliedern ist es erforderlich, dass für das Sportangebot
                                        Eltern-Kind-Turnen (bis 4 Jahre) eine Begleitperson im Niendorfer TSV erfasst
                                        ist. Für die Aufnahme dieser Begleitperson wird keine Aufnahmegebühr berechnet.
                                        Diese Mitgliedschaft endet automatisch in dem Quartal, in dem Ihr Kind 4 Jahre
                                        alt wird.
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="Mitglieds&shy;nummer&nbsp;*"
                                            controlId="sportData.attendeeMember.memberId"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeMember.memberId"
                                                    placeholder="Mitgliedsnummer"
                                                    value={values.sportData.attendeeMember?.memberId}
                                                    className="memberId"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="Vorname&nbsp;*"
                                            controlId="sportData.attendeeMember.firstName"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeMember.firstName"
                                                    placeholder="Vorname"
                                                    value={values.sportData.attendeeMember?.firstName}
                                                    className="firstName"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="Nachname&nbsp;*"
                                            controlId="sportData.attendeeMember.lastName"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeMember.lastName"
                                                    placeholder="Nachname"
                                                    value={values.sportData.attendeeMember?.lastName}
                                                    className="lastName"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="Geburtsdatum&nbsp;*"
                                            controlId="sportData.attendeeMember.dateOfBirth"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <DateInput
                                                    name="sportData.attendeeMember.dateOfBirth"
                                                    placeholder="Nachname"
                                                    value={values.sportData.attendeeMember?.dateOfBirth}
                                                    className="dateOfBirth"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <FormInputLabeled
                                            label="Geschlecht&nbsp;*"
                                            controlId="sportData.attendeeMember.gender"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <SelectField
                                                    name="sportData.attendeeMember.gender"
                                                    placeholder="Geschlecht"
                                                    value={values.sportData.attendeeMember?.gender}
                                                    className="gender"
                                                    options={[
                                                        { value: '', label: 'Bitte wählen' },
                                                        { value: 'weiblich', label: 'Weiblich' },
                                                        { value: 'männlich', label: 'Männlich' },
                                                        { value: 'unbekannt', label: 'Divers' },
                                                    ]}
                                                    data-testid="formDateInput_sportData.attendeeMember.gender"
                                                />
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            )}
        </>
    )
}

export default FormSportData
