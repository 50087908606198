import { format } from 'date-fns'
import * as React from 'react'
import { useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { useTypedStoreActions, useTypedStoreState } from 'store/hooks'

const CancelMembership = (): JSX.Element => {
    const [show, setShow] = useState(false)
    const [showWait, setShowWait] = useState(false)
    const [showError, setShowError] = useState(false)
    const cancelMembership = useTypedStoreActions((state) => state.membershipData.cancelMembership)
    let memberData = useTypedStoreState((state) => state.authentication.user?.MemberLoginData.MemberData)
    const memberIndex = useTypedStoreState((state) => state.memberIndexData.index)
    const updateMemberLoginData = useTypedStoreActions((state) => state.authentication.updateMemberLoginData)
    const setMemberIndex = useTypedStoreActions((state) => state.memberIndexData.setIndex)

    if (Array.isArray(memberData)) {
        memberData = memberData[memberIndex]
    }
    const cancelled = memberData?.Writable.VereinsAustritt._text
    let cancelledDate = ''
    if (cancelled) {
        const cancelDate = Date.parse(cancelled)
        cancelledDate = format(cancelDate, 'dd.MM.yyyy')
    }

    const showCancelDialog = () => {
        setShow(true)
    }

    const hideCancelDialog = () => {
        setShow(false)
    }

    const hideErrorDialog = () => {
        setShowError(false)
    }

    const handleCancelMembership = async () => {
        setShow(false)
        setShowWait(true)
        try {
            const result = await cancelMembership(true)
            await updateMemberLoginData(result)
            // FIXME Workaround um aktualisierungen bei den Sparten zu verursachen
            const saveIndex = memberIndex
            setMemberIndex(saveIndex + 1)
            setMemberIndex(saveIndex)
        } catch (err) {
            setShowError(true)
        }
        setShowWait(false)
    }

    return (
        <>
            <Modal
                show={showWait}
                onHide={() => {
                    /* do nothing */
                }}
            >
                <Modal.Header>
                    <Modal.Title>Mitgliedschaft kündigen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Bitte warten während wir Ihre Anfrage bearbeiten...</p>
                </Modal.Body>
            </Modal>

            <Modal show={showError} onHide={hideErrorDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>Mitgliedschaft kündigen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Es ist ein Fehler aufgetreten.</p>
                    <p>Bitte melden Sie sich ab und wieder an.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={hideErrorDialog}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={hideCancelDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>Mitgliedschaft kündigen</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        Der Austritt aus dem Niendorfer TSV von 1919 e.V. kann laut Vereinssatzung mit einer Frist von
                        einem Monat zum Quartalsende erfolgen.
                    </p>
                    <Table responsive>
                        <tbody>
                            <tr>
                                <th>Kündigungen bis zum</th>
                                <th>Wirksam zum</th>
                            </tr>
                            <tr>
                                <td>28./29. Februar</td>
                                <td>31. März</td>
                            </tr>
                            <tr>
                                <td>31. Mai</td>
                                <td>30. Juni</td>
                            </tr>
                            <tr>
                                <td>31. August</td>
                                <td>30. September</td>
                            </tr>
                            <tr>
                                <td>30. November</td>
                                <td>31. Dezember</td>
                            </tr>
                        </tbody>
                    </Table>

                    <p>Hiermit kündige ich fristgerecht und verbindlich meine Vereinsmitgliedschaft im NTSV.</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={hideCancelDialog}>
                        Abbrechen
                    </Button>
                    <Button variant="primary" onClick={handleCancelMembership}>
                        Vereinsmitgliedschaft fristgerecht und verbindlich kündigen
                    </Button>
                </Modal.Footer>
            </Modal>
            <div>
                <hr />
                {!cancelled && (
                    <p>
                        Mitgliedschaft kündigen{' '}
                        <Button variant="outline-primary" onClick={showCancelDialog} size="sm">
                            Ich möchte austreten
                        </Button>
                    </p>
                )}
                {cancelled && <p>Mitgliedschaft beendet zum {cancelledDate}.</p>}
            </div>
        </>
    )
}

export default CancelMembership
