import * as React from 'react'
import FormSportData, { ValidationSchemaSportData } from 'shared_components/FormSportData'
import { SportDataType } from 'shared_components/FormSportData/SportData'
import styles from 'shared_components/forms.module.scss'
import { Formik } from 'formik'
import { Button, Form } from 'react-bootstrap'
import { useIsMounted } from 'common'
import * as Yup from 'yup'
import { useTypedStoreActions } from 'store/hooks'
import { toast } from 'react-toastify'

interface Props {
    initialValues: SportDataType
    sportData: SportDataType
}

const SportData = ({ initialValues, sportData }: Props): JSX.Element => {
    const isMounted = useIsMounted()
    const updateMemberLoginData = useTypedStoreActions((state) => state.authentication.updateMemberLoginData)
    const updateSportData = useTypedStoreActions((state) => state.sportData.updateSportData)

    const validationSchema = Yup.object().shape(ValidationSchemaSportData)
    const today = new Date()

    const onCreate = async (values: SportDataType) => {
        try {
            const result = await updateSportData(values)
            await updateMemberLoginData(result)

            if (result) {
                setTimeout(() => window.location.reload(), 1000)
            }
        } catch (err) {
            toast.error(<div data-testid="failureToast">{(err as Error).message}</div>)
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }): Promise<void> => {
                setSubmitting(true)

                await onCreate(values)

                if (isMounted()) {
                    setSubmitting(false)
                }
            }}
        >
            {({ values, handleSubmit, handleReset, isSubmitting }): JSX.Element => (
                <Form onSubmit={handleSubmit} onReset={handleReset} data-testid="form" className={styles.form}>
                    <FormSportData values={values} initialValues={initialValues} sportData={sportData} today={today} />
                    <Button id="submit-sport-form" type="submit" disabled={isSubmitting} className="float-right">
                        Speichern
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

export default SportData
