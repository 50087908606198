import { InfoIcon } from 'common'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import styles from './FieldInfo.module.scss'

type Props = {
    tipId: string
    trigger?: 'hover' | 'click'
    children: React.ReactNode
}

const renderPopover = ({ tipId, children }: Props) => {
    return (
        <Popover id={`toolTip_${tipId}`}>
            <Popover.Content>{children}</Popover.Content>
        </Popover>
    )
}

const FieldInfo = (props: Props): JSX.Element => {
    const trigger = props.trigger || undefined // use undefined as fallback not hover, hover will create warnings
    return (
        <OverlayTrigger trigger={trigger} placement="top" overlay={renderPopover(props)}>
            <span className={styles.fieldinfo}>
                <InfoIcon />
            </span>
        </OverlayTrigger>
    )
}

export default FieldInfo
