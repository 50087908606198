import { Formik } from 'formik'
import useArvAuth from 'hooks/useArvAuth'
import { Button, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { EmailInput, PasswordInput, useIsMounted } from 'common'
import styles from 'shared_components/forms.module.scss'

export interface Props {
    visible: boolean
    handleClose: () => void
}

type PasswordResetData = {
    email: string
    newPassword: string
}

const LostPasswordModal = ({ handleClose, visible }: Props): JSX.Element => {
    const { resetPassword } = useArvAuth()
    const isMounted = useIsMounted()

    const onSubmit = async (values: PasswordResetData) => {
        try {
            await resetPassword(values.email, values.newPassword)
        } catch (err) {
            toast.error(<div data-testid="failureToast">{(err as Error).message}</div>)
        }
    }

    const initialValues: PasswordResetData = { email: '', newPassword: '' }
    const validationSchema = Yup.object({
        email: Yup.string().required('Pflichtfeld'),
        newPassword: Yup.string().required('Bitte ein Passwort eingeben'),
    })

    return (
        <Modal show={visible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Passwort vergessen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }): Promise<void> => {
                        setSubmitting(true)

                        await onSubmit(values)

                        if (isMounted()) {
                            setSubmitting(false)
                        }
                    }}
                >
                    {({ isSubmitting, handleSubmit, handleReset }): JSX.Element => (
                        <Form onSubmit={handleSubmit} onReset={handleReset} data-testid="form" className={styles.form}>
                            <EmailInput name="email" value="" className="email" placeholder="E-Mailadresse" />
                            <PasswordInput
                                name="newPassword"
                                value=""
                                className="newPassword"
                                placeholder="Neues Passwort"
                            />

                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                Absenden
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default LostPasswordModal
