import * as Yup from 'yup'

const ValidationSchemaSportData = {
    sportData: Yup.object({
        attendeeNew: Yup.object().when(['childAthletics', 'attendee'], {
            is: (childAthletics: boolean, attendee: 'new' | 'member') => childAthletics && attendee === 'new',
            then: Yup.object({
                firstName: Yup.string().required(' '),
                lastName: Yup.string().required(' '),
                dateOfBirth: Yup.string().required(' '),
                street: Yup.string().required(' '),
                streetNumber: Yup.string().required(' '),
                zip: Yup.number()
                    .test('length', '5 stellige PLZ', (value) => value?.toString().length === 5)
                    .required(' '),
                city: Yup.string().required(' '),
                email: Yup.string().email().required(' '),
            }),
        }),
        attendeeMember: Yup.object().when(['childAthletics', 'attendee'], {
            is: (childAthletics: boolean, attendee: 'new' | 'member') => childAthletics && attendee === 'member',
            then: Yup.object({
                memberId: Yup.string().required(' '),
                firstName: Yup.string().required(' '),
                lastName: Yup.string().required(' '),
                dateOfBirth: Yup.string().required(' '),
            }),
        }),
    }),
}

export default ValidationSchemaSportData
