import { SportDataSections, SportDataType } from './SportData'

export const InitialValuesSportDataSimple: SportDataSections = {
    baseball: false,
    basketball: false,
    soccer: false,
    handball: false,
    judo: false,
    jujutsu: false,
    karate: false,
    athleticsAddons: {
        athletics: false,
        walking: false,
    },
    chess: false,
    swimming: false,
    danceAddon_ballett: false,
    danceAddon_bellydance: false,
    danceAddon_breakdance: false,
    danceAddon_hiphop: false,
    danceAddon_contemporary: false,
    danceAddon_creativeChildrendance: false,
    danceAddon_linedance: false,
    danceAddon_pairdance: false,
    danceAddon_tapdance: false,
    tableTennis: false,
    theatre: false,
    volleyball: false,
    adyton: false,
    adytonAddon: 'gold',
    tennis: false,
    tennisAddonChild: 'none',
    tennisAddonAdult: 'none',
    freeAthletics: false,
    aikido: false,
    badminton: false,
    kungfu: false,
    lungSport: false,
    krebsSport: false,
    osteoporose: false,
    backFit: false,
    trampoline: false,
    trampolineAddon: 'erstesKind',
    sportgymnastik: false,
    yoga: false,
    childAthletics: false,
    onewheelAddons: {
        onewheel: false,
        hockey: false,
    },
}

const InitialValuesSportData: SportDataType = {
    sportData: {
        ...InitialValuesSportDataSimple,
        attendee: 'new',
        attendeeNew: {
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            street: '',
            streetNumber: '',
            zip: '',
            city: '',
            email: '',
            gender: '',
        },
    },
    endingData: {
        baseball: undefined,
        basketball: undefined,
        soccer: undefined,
        handball: undefined,
        judo: undefined,
        jujutsu: undefined,
        karate: undefined,
        athleticsAddons: {
            athletics: undefined,
            walking: undefined,
        },
        chess: undefined,
        swimming: undefined,
        danceAddon_ballett: undefined,
        danceAddon_bellydance: undefined,
        danceAddon_breakdance: undefined,
        danceAddon_hiphop: undefined,
        danceAddon_contemporary: undefined,
        danceAddon_creativeChildrendance: undefined,
        danceAddon_linedance: undefined,
        danceAddon_pairdance: undefined,
        danceAddon_tapdance: undefined,
        tableTennis: undefined,
        theatre: undefined,
        volleyball: undefined,
        adyton: undefined,
        tennis: undefined,
        freeAthletics: undefined,
        aikido: undefined,
        badminton: undefined,
        kungfu: undefined,
        lungSport: undefined,
        krebsSport: undefined,
        osteoporose: undefined,
        backFit: undefined,
        trampoline: undefined,
        sportgymnastik: undefined,
        yoga: undefined,
        childAthletics: undefined,
        onewheelAddons: {
            onewheel: undefined,
            hockey: undefined,
        },
    },
}

export default InitialValuesSportData
