import * as React from 'react'
import { Button } from 'react-bootstrap'
import { ArvCourseDetails } from 'hooks/transformToJson'

interface GroupBookBtnI {
    course: ArvCourseDetails
    setCourse: (arg: React.SetStateAction<undefined>) => void
    setShowAddPopup: (arg: boolean) => void
}

const GroupBookBtn = (props: GroupBookBtnI): JSX.Element => {
    const { course, setCourse, setShowAddPopup } = props
    return (
        <>
            {course.FreiePlaetze?._text && parseInt(course.FreiePlaetze._text) !== 0 && (
                <Button
                    variant="outline-primary"
                    onClick={() => {
                        setShowAddPopup(true)
                        // @ts-ignore
                        setCourse(course)
                    }}
                >
                    Anmelden
                </Button>
            )}
        </>
    )
}

export default GroupBookBtn
