import * as Sentry from '@sentry/react'
import { Container, Row, Col } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { useTypedStoreActions, useTypedStoreState } from 'store/hooks'
import { H1 } from 'common'
import useArvAuth from 'hooks/useArvAuth'
import BoxArrowRight from 'common/atoms/Icons/BoxArrowRight'
import styles from './LoggedInHeader.module.scss'

const LoggedInHeader = (): JSX.Element => {
    const { logout } = useArvAuth()
    const setIsAuthenticated = useTypedStoreActions((state) => state.authentication.setIsAuthenticated)
    const setUser = useTypedStoreActions((state) => state.authentication.setUser)
    const userEmail = useTypedStoreState((state) => state.authentication.userEmail)
    const userToken = useTypedStoreState((state) => state.authentication.userToken)

    const handleLogout = async () => {
        try {
            await logout(userEmail, userToken)
            setUser(null)
            setIsAuthenticated(false)
        } catch (err) {
            Sentry.captureException(err, { level: Sentry.Severity.Warning })
        } finally {
            setUser(null)
            setIsAuthenticated(false)
            return <Redirect to={{ pathname: '/' }} />
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <H1>Mein Niendorfer TSV</H1>
                </Col>
                <Col className="text-right">
                    <a className={styles.notUnderlined} onClick={handleLogout}>
                        <strong>
                            Ausloggen <BoxArrowRight width={16} />
                        </strong>
                    </a>
                </Col>
            </Row>
        </Container>
    )
}

export default LoggedInHeader
