import { Col, Row } from 'react-bootstrap'
import { PersonalDataType } from './PersonalData'

import {
    DateInput,
    EmailInput,
    FormInputLabeled,
    H2,
    NumberInput,
    RadioButtonGroup,
    SelectField,
    TextInput,
} from 'common'
import FieldInfo from 'shared_components/FieldInfo'

interface Props {
    values: PersonalDataType
    mode?: 'create' | 'update'
}

const FormPersonalData = ({ values, mode }: Props): JSX.Element => {
    const formMode = !mode ? 'create' : mode

    // TODO: Could be removed after promotion end after 30.06.2024
    const currentDate = new Date()
    const dateFrom = new Date('2024-01-31T00:00:00')
    const dateTo = new Date('2024-06-30T23:59:59')
    const isPromotionVisible = currentDate > dateFrom && currentDate < dateTo

    return (
        <>
            <H2>Ihre persönlichen Daten</H2>
            <Row className="pb-4">
                <Col>
                    {formMode === 'create' && (
                        <FormInputLabeled
                            controlId="personalData.membershipType"
                            label="Mitgliedschaft&nbsp;*"
                            xs={4}
                            lg={2}
                            align="start"
                            inputComponent={
                                <RadioButtonGroup
                                    name="personalData.membershipType"
                                    options={[
                                        { label: 'aktives Mitglied', value: 'active' },
                                        { label: 'passives Mitglied (Fördermitglied)', value: 'passive' },
                                    ]}
                                    value={values.personalData.membershipType}
                                    className="membershipType"
                                    inline={true}
                                />
                            }
                        />
                    )}
                </Col>
            </Row>
            <Row className="pb-4">
                <Col lg={6}>
                    <div className="pb-4">
                        <FormInputLabeled
                            label="Titel"
                            controlId="personalData.title"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.title"
                                    placeholder="Titel"
                                    value={values.personalData.title}
                                    className="title"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Vorname&nbsp;*"
                            controlId="personalData.firstName"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.firstName"
                                    placeholder="Vorname"
                                    value={values.personalData.firstName}
                                    className="firstName"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Nachname&nbsp;*"
                            controlId="personalData.lastName"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.lastName"
                                    placeholder="Name"
                                    value={values.personalData.lastName}
                                    className="lastName"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Straße&nbsp;*"
                            controlId="personalData.street"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.street"
                                    placeholder="Straße"
                                    value={values.personalData.street}
                                    className="street"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="HausNr.&nbsp;*"
                            controlId="personalData.streetNumber"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.streetNumber"
                                    placeholder="HausNr."
                                    value={values.personalData.streetNumber}
                                    className="streetNumber"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="PLZ&nbsp;*"
                            controlId="personalData.zip"
                            labelColumnSize={4}
                            inputComponent={
                                <NumberInput
                                    name="personalData.zip"
                                    placeholder="PLZ"
                                    value={values.personalData.zip}
                                    className="zip"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Ort&nbsp;*"
                            controlId="personalData.city"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.city"
                                    placeholder="Ort"
                                    value={values.personalData.city}
                                    className="city"
                                />
                            }
                        />
                    </div>
                </Col>
                <Col lg={6}>
                    {formMode === 'create' && (
                        <div className="pb-4">
                            <FormInputLabeled
                                label={
                                    <>
                                        Familien&shy;mitglieds&shy;nummer
                                        <FieldInfo tipId="memberId">
                                            <div>
                                                <p>
                                                    Bei einer bestehenden Mitgliedschaft, bitte diese Nummer angeben.
                                                    Bei Neuanmeldungen einer Familienmitgliedschaft führen Sie bitte
                                                    zunächst eine Einzelanmeldung durch. Mit der erhaltenen
                                                    Mitgliedsnummer können Sie weitere Familienmitglieder hinzufügen.
                                                    Bitte <strong>OHNE</strong> laufende Nummer und Punkt eingeben
                                                    <strong>12345</strong>.6.
                                                </p>
                                            </div>
                                        </FieldInfo>
                                    </>
                                }
                                controlId="personalData.memberId"
                                labelColumnSize={4}
                                inputComponent={
                                    <TextInput
                                        name="personalData.memberId"
                                        placeholder="Nummer vor dem Punkt - siehe Info"
                                        value={values.personalData.memberId}
                                        className="memberId"
                                        maxLength="5"
                                        isValid={
                                            /^\d+$/.test(values.personalData.memberId) ||
                                            values.personalData.memberId.length === 0
                                        }
                                    />
                                }
                            />
                        </div>
                    )}

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Geburtsdatum&nbsp;*"
                            controlId="personalData.dateOfBirth"
                            labelColumnSize={4}
                            inputComponent={
                                <DateInput
                                    name="personalData.dateOfBirth"
                                    placeholder="Geburtsdatum"
                                    value={values.personalData.dateOfBirth}
                                    className="dateOfBirth"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Geschlecht&nbsp;*"
                            controlId="personalData.gender"
                            labelColumnSize={4}
                            inputComponent={
                                <SelectField
                                    name="personalData.gender"
                                    placeholder="Geschlecht"
                                    value={values.personalData.gender}
                                    className="gender"
                                    options={[
                                        { value: '', label: 'Bitte wählen' },
                                        { value: 'weiblich', label: 'Weiblich' },
                                        { value: 'männlich', label: 'Männlich' },
                                        { value: 'unbekannt', label: 'Divers' },
                                    ]}
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Telefon&nbsp;*"
                            controlId="personalData.phone"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    type="tel"
                                    name="personalData.phone"
                                    placeholder="04012345678"
                                    value={values.personalData.phone}
                                    className="phone"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="E-Mail-Adresse&nbsp;*"
                            controlId="personalData.email"
                            labelColumnSize={4}
                            inputComponent={
                                <EmailInput
                                    type="email"
                                    name="personalData.email"
                                    placeholder="hello@world"
                                    value={values.personalData.email}
                                    className="email"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Erziehungs&shy;berechtigte Person"
                            controlId="personalData.co"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.co"
                                    placeholder="Maren Muster"
                                    value={values.personalData.co}
                                    className="co"
                                />
                            }
                        />
                        <small>(Pflicht bei Minderjährigen)</small>
                    </div>
                </Col>
            </Row>
            {/* TODO: Could be removed after promotion end after 30.06.2024 */}
            {isPromotionVisible && (
                <>
                    <H2>Aktion &quot;Mitglieder werben Mitglieder&quot; 01.02.2024-30.06.2024</H2>
                    <Row className="pb-12">
                        <Col>
                            Im Verein ist Sport am schönsten. Deswegen haben wir uns für den Jahresbeginn 2024 eine ganz
                            besondere Aktion ausgedacht. Bringt eure Familie und Freunde einfach mit zum Sport und
                            erhaltet auch noch eine Belohnung dafür. <br />
                            <strong>Vom 1. Februar bis zum 30. Juni 2024</strong> gibt es im Niendorfer TSV die Aktion{' '}
                            <strong>„Mitglieder werben Mitglieder“</strong>! Und das Beste daran ist, dass sowohl das
                            NTSV-Mitglied als auch das neue Mitglied davon profitieren. Beide erhalten eine Gutschrift
                            in Höhe von 20 Euro auf den Vereinsbeitrag angerechnet. So macht der gemeinsame Sport gleich
                            noch mehr Spaß und das neue Jahr startet mit ganz viel Bewegung.
                            <br />
                            <br />
                            Wenn du gerade deine Onlineanmeldung aufgrund einer Empfehlung eines unserer Mitglieder
                            ausgefüllt hast, schicke doch bitte <strong>binnen 2 Tagen</strong> nach deiner heutigen
                            Anmeldung eine E-Mail an:{' '}
                            <a href="mailto:mitgliederverwaltung@niendorfer-tsv.de?subject=Mitglieder werben Mitglieder">
                                mitgliederverwaltung@niendorfer-tsv.de
                            </a>
                            <br />
                            Betreff: <i>“Mitglieder werben Mitglieder”</i> und dem Namen & Vornamen der Person, die dich
                            geworben hat. Eine spätere Information kann leider nicht berücksichtigt werden.
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default FormPersonalData
