import { Form, Col } from 'react-bootstrap'
import styles from './FormInputLabeled.module.scss'
import FieldInfo from 'shared_components/FieldInfo'

interface Props {
    label?: string | JSX.Element
    controlId: string
    inputComponent: React.ReactNode
    info?: string | JSX.Element
    labelColumnSize?: number
    required?: boolean
    xs?: number
    md?: number
    lg?: number
    align?: 'start' | 'end' | 'center'
}

const FormInputLabeled = ({
    label,
    controlId,
    inputComponent,
    labelColumnSize,
    required,
    info,
    xs,
    md,
    lg,
    align,
}: Props): JSX.Element => {
    const DEFAULT_LABEL_SIZE = 6
    const NO_LABEL_SIZE = 0
    const DEFAULT_INPUT_SIZE = 12
    const labelColumn = labelColumnSize ? labelColumnSize : !label ? NO_LABEL_SIZE : DEFAULT_LABEL_SIZE
    const inputColumn = labelColumn < DEFAULT_INPUT_SIZE ? DEFAULT_INPUT_SIZE - labelColumn : DEFAULT_INPUT_SIZE
    const xsSize = labelColumnSize ? labelColumnSize : xs ? xs : 6
    const classNames = ['row', styles.alignBaseline]

    switch (align) {
        case 'start':
            classNames.push(styles.alignStart)
            break
        case 'end':
            classNames.push(styles.alignEnd)
            break
    }

    const formLabel = required ? (
        <Form.Label>
            {label}
            <sup>*</sup>
        </Form.Label>
    ) : (
        <Form.Label>{label}</Form.Label>
    )
    return (
        <Form.Group controlId={controlId} className={classNames.join(' ')}>
            {label && (
                <Col xs={xsSize} md={md} lg={lg} className="col-form-label" data-testid={`formLabel_${controlId}`}>
                    {formLabel}
                </Col>
            )}
            <Col xs={inputColumn} data-testid={`formInputCol_${controlId}`}>
                <div className={styles.inputWithInfo}>
                    {inputComponent}
                    {info && (
                        <Form.Text>
                            <FieldInfo trigger="click" tipId="memberId">
                                {info}
                            </FieldInfo>
                        </Form.Text>
                    )}
                </div>
            </Col>
        </Form.Group>
    )
}

export default FormInputLabeled
